export default class Setting {
    constructor(init?: Partial<Setting>) {
        Object.assign(this, init);
    }

    public id!: number;

    public name!: string;

    public value: string = '';
}
