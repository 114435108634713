import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import Setting from '@/domain/Setting';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';

export default class SettingApiService {
    public async getCustomerSettings(): Promise<DataAccessResponse<Array<Setting>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Setting>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/settings/customer-settings`,
        });

        return new DataListAccessResponseClass<Setting>(response, Setting).response;
    }

    public async updateSetting(setting: Setting): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/settings/customer-setting`,
            data: setting,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }
}
