
import {
    defineComponent, reactive, onBeforeMount, computed,
} from 'vue';
import Setting from '@/domain/Setting';
import SettingService from '@/modules/master-data/services/SettingService';
import { FormDefinition, TableDefinition } from '@/types';
import Permissions from '@/services/permissions/Permissions';
import useValidator from '@/validation/useValidator';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import MasterDataRouteTypes from '../../routes/types';
import EntityType from '@/domain/enums/EntityTypes';
import router from '@/router';

type State = {
    settings: Array<Setting>;
    loading: boolean;
    settingsTable: TableDefinition<Setting> | null;
    settingEdit: Setting;
    showForm: boolean;
    modalTitle: string;
    saving: boolean;
};

export default defineComponent({
    name: 'customer-settings',
    components: { BSpinner },

    setup() {
        const settingService = new SettingService();

        const { validateForm, validationResult, clearResults } = useValidator<Setting>('customer-settings');

        const state = reactive<State>({
            settings: [],
            loading: true,
            settingsTable: null,
            settingEdit: new Setting(),
            showForm: false,
            modalTitle: '',
            saving: false,
        });

        onBeforeMount(async () => {
            const response = await settingService.getCustomerSettings();
            if (response.success) {
                state.settings = response.settings;
                state.loading = false;
            }
        });

        const table = computed(
            (): TableDefinition<Setting> => ({
                items: state.settings,
                key: 'settingList',
                name: getTitleCaseTranslation('core.domain.settings'),
                columnDefinition: [
                    {
                        key: 'name',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.name'),
                    },
                    {
                        key: 'value',
                        searchable: true,
                        label: getTitleCaseTranslation('core.common.value'),
                    },
                ],
            }),
        );

        const formDefinition: Array<FormDefinition<Setting>> = [
            {
                key: 'name',
                type: 'string',
                autofocus: true,
                label: getTitleCaseTranslation('core.domain.name'),
            },
            {
                key: 'value',
                type: 'string',
                label: getTitleCaseTranslation('core.common.value'),
            },
        ];

        function openEdit(setting: Setting) {
            clearResults();
            state.settingEdit = new Setting(setting);
            state.modalTitle = getTitleCaseTranslation('core.common.editSetting');
            state.showForm = true;
        }

        async function save() {
            validateForm(state.settingEdit);

            if (validationResult.isValid) {
                state.saving = true;

                const response = await settingService.updateSetting(state.settingEdit);
                if (response) {
                    state.settings = state.settings.map((data) => {
                        if (data.name === state.settingEdit.name) {
                            return state.settingEdit;
                        }
                        return data;
                    });
                    state.showForm = false;
                }

                state.saving = false;
            }
        }

        async function openSettingHistory(setting: Setting) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.SETTING, entityId: setting.id },
            });
        }

        return {
            state,
            formDefinition,
            openEdit,
            openSettingHistory,
            save,
            table,
            Permissions,
            validationResult,
            getTitleCaseTranslation,
            getTranslation,
        };
    },
});
