import SettingApiService from '@/modules/master-data/services/api/SettingApiService';
import Setting from '@/domain/Setting';
import { useNotification } from '@/composable/useNotifications';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default class SettingService {
    private settingApiService: SettingApiService;

    private notification = useNotification();

    constructor() {
        this.settingApiService = new SettingApiService();
    }

    public async getCustomerSettings(): Promise<{ settings: Array<Setting>; success: boolean }> {
        const response = await this.settingApiService.getCustomerSettings();
        if (!response.success) {
            this.notification.showError(response.message);
        }
        return { settings: response.data, success: response.success };
    }

    public async updateSetting(setting: Setting): Promise<boolean> {
        const response = await this.settingApiService.updateSetting(setting);
        if (response.success) {
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.updated')} ${setting.name}`);
        }

        return response.success;
    }
}
